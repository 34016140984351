import { Result2020Table } from "./common";

const DATA_SENATE_2020: Result2020Table[] = [
  {
    pollWeight: 10,
    rows: [
      {
        state: "Alabama",
        democrat: 39.8,
        republican: 60.2,
        strongDemocratTurnout: "(0.0%, 100.0%)",
        neutral: "(0.0%, 100.0%)",
        strongRepulbicanTurnout: "(0.0%, 100.0%)",
      },
      {
        state: "Alaska",
        democrat: 41,
        republican: 54.3,
        strongDemocratTurnout: "(26.2%, 73.8%)",
        neutral: "(26.2%, 73.8%)",
        strongRepulbicanTurnout: "(15.2%, 84.8%)",
      },
      {
        state: "Arizona",
        democrat: 51.2,
        republican: 48.8,
        strongDemocratTurnout: "(91.9%, 8.1%)",
        neutral: "(86.7%, 13.3%)",
        strongRepulbicanTurnout: "(79.4%, 20.6%)",
      },
      {
        state: "Georgia Class II*",
        democrat: 48.0,
        republican: 49.7,
        strongDemocratTurnout: "(53.2%, 46.8%)",
        neutral: "(39.9%, 60.2%)",
        strongRepulbicanTurnout: "(27.6%, 72.4%)",
      },
      {
        state: "Georgia Class III**",
        democrat: 32.9,
        republican: 25.9,
        strongDemocratTurnout: "(99.8%, 0.17%)",
        neutral: "(99.7%, 0.3%)",
        strongRepulbicanTurnout: "(99.2%, 0.6%)",
      },
      {
        state: "Iowa",
        democrat: 45.2,
        republican: 51.8,
        strongDemocratTurnout: "(47.5%, 52.5%)",
        neutral: "(34.8%, 65.2%)",
        strongRepulbicanTurnout: "(23.6%, 76.4%)",
      },
      {
        state: "Maine",
        democrat: 42.2,
        republican: 51.1,
        strongDemocratTurnout: "(9.0%, 91.0%)",
        neutral: "(5.3%, 94.7%)",
        strongRepulbicanTurnout: "(3.0%, 97.0%)",
      },
      {
        state: "Michigan",
        democrat: 49.8,
        republican: 48.3,
        strongDemocratTurnout: "(99.8%, 0.2%)",
        neutral: "(99.3%, 0.7%)",
        strongRepulbicanTurnout: "(97.9%, 2.1%)",
      },
      {
        state: "Montana",
        democrat: 45,
        republican: 55,
        strongDemocratTurnout: "(14.8%, 85.2%)",
        neutral: "(11.7%, 88.3%)",
        strongRepulbicanTurnout: "(9.1%, 90.9%)",
      },
      {
        state: "North Carolina",
        democrat: 46.9,
        republican: 48.7,
        strongDemocratTurnout: "(92.6%, 7.4%)",
        neutral: "(82.8%, 17.2%)",
        strongRepulbicanTurnout: "(67.2%, 32.8%)",
      },
      {
        state: "South Carolina",
        democrat: 44.2,
        republican: 54.5,
        strongDemocratTurnout: "(11.8%, 88.2%)",
        neutral: "(8.7%, 91.3%)",
        strongRepulbicanTurnout: "(6.3%, 93.7%)",
      },
    ],
  },
  {
    pollWeight: 30,
    rows: [
      {
        state: "Alabama",
        democrat: 39.8,
        republican: 60.2,
        strongDemocratTurnout: "(0.0%, 100.0%)",
        neutral: "(0.0%, 100.0%)",
        strongRepulbicanTurnout: "(0.0%, 100.0%)",
      },
      {
        state: "Alaska",
        democrat: 41,
        republican: 54.3,
        strongDemocratTurnout: "(12.4%, 87.6%)",
        neutral: "(12.4%, 87.6%)",
        strongRepulbicanTurnout: "(2.7%, 97.3%)",
      },
      {
        state: "Arizona",
        democrat: 51.2,
        republican: 48.8,
        strongDemocratTurnout: "(99.9%, 0.1%)",
        neutral: "(99.4%, 0.6%)",
        strongRepulbicanTurnout: "(97.9%, 2.1%)",
      },
      {
        state: "Georgia Class II*",
        democrat: 48.0,
        republican: 49.7,
        strongDemocratTurnout: "(69.0%, 31.0%)",
        neutral: "(46.4%, 53.6%)",
        strongRepulbicanTurnout: "(24.9%, 75.1%)",
      },
      {
        state: "Georgia Class III**",
        democrat: 32.9,
        republican: 25.9,
        strongDemocratTurnout: "(100.0%, 0.0%)",
        neutral: "(100.0%, 0.0%)",
        strongRepulbicanTurnout: "(100.0%, 0.0%)",
      },
      {
        state: "Iowa",
        democrat: 45.2,
        republican: 51.8,
        strongDemocratTurnout: "(60.5%, 39.5%)",
        neutral: "(38.0%, 62.0%)",
        strongRepulbicanTurnout: "(19.0%, 81.0%)",
      },
      {
        state: "Maine",
        democrat: 42.2,
        republican: 51.1,
        strongDemocratTurnout: "(58.1%, 41.9%)",
        neutral: "(38.7%, 61.3%)",
        strongRepulbicanTurnout: "(21.8%, 78.2%)",
      },
      {
        state: "Michigan",
        democrat: 49.8,
        republican: 48.3,
        strongDemocratTurnout: "(100.0%, 0.0%)",
        neutral: "(100.0%, 0.0%)",
        strongRepulbicanTurnout: "(99.9%, 0.1%)",
      },
      {
        state: "Montana",
        democrat: 45,
        republican: 55,
        strongDemocratTurnout: "(23.5%, 76.5%)",
        neutral: "(16.7%, 83.3%)",
        strongRepulbicanTurnout: "(11.4%, 88.6%)",
      },
      {
        state: "North Carolina",
        democrat: 46.9,
        republican: 48.7,
        strongDemocratTurnout: "(99.5%, 0.5%)",
        neutral: "(95.9%, 4.1%)",
        strongRepulbicanTurnout: "(80.7%, 19.3%)",
      },
      {
        state: "South Carolina",
        democrat: 44.2,
        republican: 54.5,
        strongDemocratTurnout: "(16.8%, 83.2%)",
        neutral: "(10.5%, 89.5%)",
        strongRepulbicanTurnout: "(6.2%, 93.8%)",
      },
    ],
  },
  {
    pollWeight: 100,
    rows: [
      {
        state: "Alabama",
        democrat: 39.8,
        republican: 60.2,
        strongDemocratTurnout: "(0.0%, 100.0%)",
        neutral: "(0.0%, 100.0%)",
        strongRepulbicanTurnout: "(0.0%, 100.0%)",
      },
      {
        state: "Alaska",
        democrat: 41,
        republican: 54.3,
        strongDemocratTurnout: "(1.4%, 98.6%)",
        neutral: "(1.4%, 98.6%)",
        strongRepulbicanTurnout: "(0.0%, 100.0%)",
      },
      {
        state: "Arizona",
        democrat: 51.2,
        republican: 48.8,
        strongDemocratTurnout: "(100.0%, 0.0%)",
        neutral: "(100.0%, 0.0%)",
        strongRepulbicanTurnout: "(100.0%, 0.0%)",
      },
      {
        state: "Georgia Class II*",
        democrat: 48.0,
        republican: 49.7,
        strongDemocratTurnout: "(87.6%, 12.4%)",
        neutral: "(53.2%, 46.8%)",
        strongRepulbicanTurnout: "(15.9%, 84.1%)",
      },
      {
        state: "Georgia Class III**",
        democrat: 32.9,
        republican: 25.9,
        strongDemocratTurnout: "(100.0%, 0.0%)",
        neutral: "(100.0%, 0.0%)",
        strongRepulbicanTurnout: "(100.0%, 0.0%)",
      },
      {
        state: "Iowa",
        democrat: 45.2,
        republican: 51.8,
        strongDemocratTurnout: "(77.2%, 22.8%)",
        neutral: "(38.2%, 61.8%)",
        strongRepulbicanTurnout: "(8.9%, 91.1%)",
      },
      {
        state: "Maine",
        democrat: 42.2,
        republican: 51.1,
        strongDemocratTurnout: "(99.1%, 0.9%)",
        neutral: "(92.4%, 7.6%)",
        strongRepulbicanTurnout: "(69.4%, 30.6%)",
      },
      {
        state: "Michigan",
        democrat: 49.8,
        republican: 48.3,
        strongDemocratTurnout: "(100.0%, 0.0%)",
        neutral: "(100.0%, 0.0%)",
        strongRepulbicanTurnout: "(100.0%, 0.0%)",
      },
      {
        state: "Montana",
        democrat: 45,
        republican: 55,
        strongDemocratTurnout: "(31.3%, 68.7%)",
        neutral: "(17.8%, 82.2%)",
        strongRepulbicanTurnout: "(8.8%, 91.2%)",
      },
      {
        state: "North Carolina",
        democrat: 46.9,
        republican: 48.7,
        strongDemocratTurnout: "(100.0%, 0.0%)",
        neutral: "(99.9%, 0.1%)",
        strongRepulbicanTurnout: "(95.0%, 5.0%)",
      },
      {
        state: "South Carolina",
        democrat: 44.2,
        republican: 54.5,
        strongDemocratTurnout: "(17.9%, 82.1%)",
        neutral: "(7.4%, 92.6%)",
        strongRepulbicanTurnout: "(2.5%, 97.5%)",
      },
    ],
  },
];

export default DATA_SENATE_2020;
